<template>
  <div>
    <div>
      <h2>作业批改</h2>
    </div>
    <div class="main">
      <el-main>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="inputTitle" placeholder="请输入作者题目（可选）"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="inputPrinciple" placeholder="请输入作文提纲（可选）"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="gradeValue" placeholder="请选择年级（可选）">
              <el-option
                  v-for="item in gradeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-input v-model="outputTotalPoints" placeholder="总得分" center="true" disabled></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input v-model="inputEssayTitle" placeholder="请输入范文标题（可选）"></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="inputEssayContent" placeholder="请输入范文内容（可选）"></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="outputVocabularyPoints" placeholder="词汇得分，占比" disabled></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="outputSentencePoints" placeholder="句子得分，占比" disabled></el-input>
          </el-col>
        </el-row>
        <el-row>
          <span> </span>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2">
            <el-upload
                class="upload-demo"
                action="#"
                :before-upload="beforeUpload"
                :limit="1"
                :show-file-list="false"
                :file-list="fileList">
              <el-button type="primary">点击上传</el-button>
            </el-upload>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="callApi">提交</el-button>
          </el-col>
          <div class="white-null">
            <el-col :span="8">
              <span>-</span>
            </el-col>
          </div>
          <el-col :span="6">
            <el-input v-model="outputStructure" placeholder="篇章得分，占比" disabled></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="outputContent" placeholder="内容得分，占比" disabled></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-image :src="src">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-col>
          <el-col :span="12">
            <div class="text-content">
              <div class="text-one">
                <el-input
                    type="textarea"
                    :rows="8"
                    placeholder="无法查看"
                    v-bind:style="{marginRight: '40px'}"
                    disabled
                    v-model="compositeComment">
                </el-input>
              </div>
              <div class="text-tow">
                <el-input
                    type="textarea"
                    :rows="12"
                    placeholder="无法查看"
                    disabled
                    v-model="sentenceComment">
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      input: '',
      inputTitle: '',
      inputPrinciple: '',
      outputTotalPoints: '',
      inputEssayTitle: '',
      inputEssayContent: '',
      outputVocabularyPoints: '',
      outputSentencePoints: '',
      outputStructure: '',
      outputContent: '',
      gradeList: [{
        value: 'elementary',
        label: '小学'
      }, {
        value: 'grade7',
        label: '初一'
      }, {
        value: 'grade8',
        label: '初二'
      }, {
        value: 'grade9',
        label: '初三'
      }, {
        value: 'grade10',
        label: '高一'
      }, {
        value: 'grade11',
        label: '高二'
      }, {
        value: 'grade12',
        label: '高三'
      }, {
        value: 'cet4',
        label: '英语4级'
      }, {
        value: 'cet6',
        label: '英语6级'
      }],
      gradeValue: '',
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      textarea: '测试',
      compositeComment: '综合评价',
      sentenceComment: '句子评价',
      fileList: [],
    }
  },
  methods: {
    beforeUpload(file) {
      this.toBase64(file);
      return false;//取消自动上传
    },
    toBase64(file) {
      const self = this;
      let reader = new FileReader();
      reader.onloadend = function () {
        self.src = reader.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    callApi() {
      axios.post('/api/CorrectMultiImage', {
        "ImageBase64": this.src,
        "Title": this.inputTitle,
        "Grade": this.gradeValue,
        "Requirement": this.inputPrinciple,
        "ModelTitle": this.inputEssayTitle,
        "ModelContent": this.inputEssayContent,
      }).then((res) => {
        let code = res.data['Code'];
        let msg = res.data['Message'];
        if (code === "0") {
          try {
            let objMsg = JSON.parse(msg)['Response']['Data'];
            this.outputTotalPoints = "总得分" + objMsg['CorrectData']['Score'];
            let scoreCat = objMsg['CorrectData']['ScoreCat'];
            this.outputVocabularyPoints = "词汇得分" + scoreCat['Words']['Score'] + "，占比" + scoreCat['Words']['Percentage'] + "%";
            this.outputSentencePoints = "句子得分" + scoreCat['Sentences']['Score'] + "，占比" + scoreCat['Sentences']['Percentage'] + "%";
            this.outputStructure = "篇章结构得分" + scoreCat['Structure']['Score'] + "，占比" + scoreCat['Structure']['Percentage'] + "%";
            this.outputContent = "内容得分" + scoreCat['Content']['Score'] + "，占比" + scoreCat['Content']['Percentage'] + "%";
            this.compositeComment = "综合评价:\n" + objMsg['CorrectData']['Comment'];
            this.sentenceComment = "句子评价:\n";
            for(let idx = 0; idx < objMsg['CorrectData']['SentenceComments'].length; ++idx) {
              let sentence = objMsg['CorrectData']['SentenceComments'][idx];
              this.sentenceComment += (idx+1).toString() + "、"
              for(let suggestion of sentence['Suggestions']) {
                this.sentenceComment += suggestion['ErrorType'] + suggestion['Message'];
              }
              this.sentenceComment += '\n'
            }
            console.log(objMsg);
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log(msg);
        }
      }).finally(function (err) {
        console.log(err);
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.text {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.white-null {
  color: white
}

.text-content {

}

.text-one {
  margin-bottom: 20px;
}

.text-tow {

}

</style>
